import React from 'react'

interface EmailProps {
  emailState: { value: string, errorMessage: string }
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const getClassNames = (error: boolean): string => 
  error 
    ? 'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md' 
    : 'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base'

const Email = ({ emailState, onChange }: EmailProps) => {
  const { value: email, errorMessage } = emailState
  const error = errorMessage !== ''
  return (
    <div>
      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
        Email
      </label>
      <div className="mt-1">
        <input
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          className={getClassNames(error)}
          placeholder="you@example.com"
          value={email}
          aria-invalid={error}
          aria-describedby="email-error"
          onChange={onChange}
        />
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {errorMessage}
        </p>
      )}
    </div>
  )
}

export default Email
