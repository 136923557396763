import React, { ClipboardEventHandler, useRef } from 'react'

interface OtpProps {
  otpState: { value: string[], errorMessage: string }
  setOtp: React.Dispatch<React.SetStateAction<{ value: string[], errorMessage: string; }>>
}

const getClassNames = (error: boolean): string => 
  error 
    ? 'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md' 
    : 'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'

const Otp = ({ otpState, setOtp }: OtpProps) => {
  const { value: otp, errorMessage } = otpState
  const error = errorMessage !== ''

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !/^[0-9]{1}$/.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== "Delete" &&
      e.key !== "Tab" &&
      !e.metaKey
    ) {
      e.preventDefault();
    }

    if (e.key === "Delete" || e.key === "Backspace") {
      const index = inputRefs.current.findIndex((ref) => ref === e.target);
      setOtp((prevOtp) => {
        const newOtp = [
          ...prevOtp.value.slice(0, index),
          "",
          ...prevOtp.value.slice(index + 1),
        ]
        return {
          ...prevOtp,
          value: newOtp
        }
      });
      if (index > 0) {
        (inputRefs.current[index - 1] as HTMLInputElement).focus();
      }
    }
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    const index = inputRefs.current.findIndex((ref) => ref === e.target);
    if (target.value) {
      setOtp((prevOtp) => {
        const newOtp = [
          ...prevOtp.value.slice(0, index),
          target.value,
          ...prevOtp.value.slice(index + 1),
        ]
        return {
          ...prevOtp,
          value: newOtp
        }
      });
      if (index < otp.length - 1) {
        (inputRefs.current[index + 1] as HTMLInputElement).focus();
      }
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const text = e.clipboardData?.getData("text") ?? '';
    if (!new RegExp(`^[0-9]{${otp.length}}$`).test(text)) {
      return;
    }
    const digits = text.split("");
    setOtp((prevOtp) => ({
      ...prevOtp,
      value: digits
    }));
  };

  return (
      <div className="container">
        <div className='grid grid-cols-6 gap-2'>
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength={1}
                value={digit}
                onChange={handleInput}
                onKeyDown={handleKeyDown}
                onFocus={handleFocus}
                onPaste={handlePaste}
                ref={(el) => (inputRefs.current[index] = el)}
                className={`shadow-xs flex items-center justify-center border border-stroke bg-white p-2 text-center font-medium text-gray-5 outline-none sm:text-2xl border-gray-300 dark:bg-white/5 flex-grow-1

                  rounded-lg`}
              />
            ))}
          </div>
          <p className="mt-1.5 text-sm text-gray-500">
            We stuurden een OTP naar je email.
          </p>
      </div>
  )
}

export default Otp