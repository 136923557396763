import axios from 'axios'
import update from 'immutability-helper'
import React, { useEffect, useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import useAccessTokenProvider from '../providers/AccessTokenProvider'

const vivenuApi = process.env.REACT_APP_VIVENU_API_ENDPOINT

type Me = {
  _id: string,
  email: string,
  name: string,
  isAdmin: boolean,
  isSuperAdmin: boolean,
  sellers: Array<string>
}

type Seller = {
  _id: string,
  name: string,
  image: string,
  backgroundImage: string
}

type Event = {
  _id: string,
  sellerId: string,
  name: string,
  image: string,
  start: string,
  timezone: string,
  seating: {
    active: boolean,
    eventId: string,
    contingents: Array<string>
  },
  categories: any,
  tickets: any
}

type Ticket = {
  _id: string,
  name: string,
  categoryRef: string,
  price: number,
  amount: number,
  active: boolean,
  sold: number,
  free: number,
  reserved: number,
  pos: number
}

type Category = {
  _id: string,
  name: string,
  amount: number,
  ref: string,
  seatingReference: string,
  active: boolean
}

type EventStats = {
  _id: string,
  sellerId: string,
  name: string,
  tickets: Array<Ticket>,
  categories: Array<Category>,
  sold: number,
  maxAmount: number
}

type State = {
  me: Me | null,
  seller: Seller | null,
  sellers: Array<any>,
  tokens: { [key: string]: string; } | null,
  event: Event | null,
  eventStats: EventStats | null,
  rehydrating: boolean,
  requireOtp: boolean
}

type Context = {
  authState: State,
  login: (email: string, password: string, otp?: string) => void
  logout: () => void
  signTokenForSeller: (_id: string) => void
  setEvent: (event: Event | null) => void
  setEventStats: (eventStats: EventStats | null) => void
  setSellers: (sellers: Array<Seller>) => void
}

// @ts-ignore
const AuthContext = React.createContext()
AuthContext.displayName = 'AuthContext'

const initState: State = {
  me: null,
  seller: null,
  sellers: [],
  tokens: null,
  event: null,
  eventStats: null,
  rehydrating: false,
  requireOtp: false
}

const reducer = (state: State, action: any) => {
  switch (action.type) {
    case 'set_require_otp': {
      return update(state, {
        requireOtp: { $set: action.requireOtp }
      })
    }
    case 'set_me': {
      return update(state, {
        me: { $set: action.me },
        seller: { $set: action.seller },
        sellers: { $set: action.sellers },
        tokens: { $set: action.tokens }
      })
    }
    case 'set_seller': {
      return update(state, {
        seller: { $set: action.seller }
      })
    }
    case 'clear_state': {
      return update(state, { $set: initState })
    }
    case 'set_rehydrating': {
      return update(state, { 
        rehydrating: { $set: action.rehydrating }
      })
    }
    case 'set_event': {
      return update(state, {
        event: { $set: action.event }
      })
    }
    case 'set_event_stats': {
      return update(state, {
        eventStats: { $set: action.eventStats }
      })
    }
    case 'set_sellers': {
      return update(state, {
        sellers: { $set: action.sellers }
      })
    }
  }
  throw Error('Unknown action: ' + action.type)
}

const AuthProvider = (props: any) => {
  const navigate = useNavigate()
  const [token, setToken, removeToken] = useAccessTokenProvider()

  /* State */
  const [state, dispatch] = useReducer(reducer, initState)

  const login = async (email: string, password: string, otp?: string) => {
    // Perform login
    try {
      const loginResponse = state.requireOtp 
        ? await axios.post(`${vivenuApi}/users/login`, { email, password, otp }) 
        : await axios.post(`${vivenuApi}/users/login`, { email, password, sendOtpMail: true })

      if (loginResponse.data.next === 'otp') {
        dispatch({ type: 'set_require_otp', requireOtp: true })
        return
      }

      setToken(loginResponse.data.token)

      const meResponse = await axios.get(`${vivenuApi}/sellers/me`, { headers: { Authorization: `Bearer ${loginResponse.data.token}` }})
      const { seller, me } = meResponse.data

      const sellersResponse = await axios.get(`${vivenuApi}/sellers`, { headers: { Authorization: `Bearer ${loginResponse.data.token}` }})

      const { docs } = sellersResponse.data
      const sellers = docs.map((seller: any) => ({ _id: seller._id, name: seller.name, image: seller.image, backgroundImage: seller.backgroundImage }))
      
      const tokens: { [key: string]: string; } = {}

      for (const seller of sellers) {
        const accessResponse = await axios.post(`${vivenuApi}/users/access-seller`, { sellerId: seller._id }, { headers: { Authorization: `Bearer ${loginResponse.data.token}` }})
        const refreshResponse = await axios.post(`${vivenuApi}/users/refresh`, null, { headers: { Authorization: `Bearer ${loginResponse.data.token}` }})
        tokens[seller._id] = refreshResponse.data.token
      }

      dispatch({ 
        type: 'set_me', 
        me: { _id: me._id, email: me.email, name: me.name, isAdmin: me.isAdmin, isSuperAdmin: me.isSuperAdmin, sellers: me.sellers }, 
        seller: { _id: seller._id, name: seller.name, image: seller.image, backgroundImage: seller.backgroundImage }, 
        sellers,
        tokens
      })
      navigate('/events', { replace: true })

    } catch (error: any) {
      if (error.response.status === 401) {
        throw error
      }
    }
  }

  const logout = async () => {
    removeToken()
    dispatch({ type: 'clear_state' })
    navigate('/', { replace: true })
  }

  const signTokenForSeller = async (_id: string) => {
    try {
      const accessResponse = await axios.post(`${vivenuApi}/users/access-seller`, { sellerId: _id }, { headers: { Authorization: `Bearer ${token}` }})
      const refreshResponse = await axios.post(`${vivenuApi}/users/refresh`, null, { headers: { Authorization: `Bearer ${token}` }})
      
      const newSeller = state.sellers.find((seller: Seller) => seller._id === _id)
      setToken(refreshResponse.data.token)
      dispatch({ 
        type: 'set_seller',
        seller: newSeller
      }) 
    } catch (error: any) {

    }
  }

  const setEvent = (event: Event) => {
    dispatch({ type: 'set_event', event })
  } 

  const setEventStats = (eventStats: EventStats) => {
    dispatch({ type: 'set_event_stats', eventStats })
  } 

  const setSellers = (sellers: Array<Seller>) =>
    dispatch({ type: 'set_sellers', sellers })

  /** Lifecycle methods */
  useEffect(() => {
    const fetchMe = async () => {
      try {        
        const meResponse = await axios.get(`${vivenuApi}/sellers/me`, { headers: { Authorization: `Bearer ${token}` }})
        const { seller, me } = meResponse.data

        const sellersResponse = await axios.get(`${vivenuApi}/sellers`, { headers: { Authorization: `Bearer ${token}` }, params: { skip: 0, top: 10 }})

        const { docs } = sellersResponse.data
        const sellers = docs.map((seller: any) => ({ _id: seller._id, name: seller.name, image: seller.image, backgroundImage: seller.backgroundImage }))

        const tokens: { [key: string]: string; } = {}

        for (const seller of sellers) {
          const accessResponse = await axios.post(`${vivenuApi}/users/access-seller`, { sellerId: seller._id }, { headers: { Authorization: `Bearer ${token}` }})
          const refreshResponse = await axios.post(`${vivenuApi}/users/refresh`, null, { headers: { Authorization: `Bearer ${token}` }})
          tokens[seller._id] = refreshResponse.data.token
        }

        dispatch({ 
          type: 'set_me', 
          me: { _id: me._id, email: me.email, name: me.name, isAdmin: me.isAdmin, isSuperAdmin: me.isSuperAdmin, sellers: me.sellers }, 
          seller: { _id: seller._id, name: seller.name, image: seller.image, backgroundImage: seller.backgroundImage },
          sellers,
          tokens
        })
        navigate('/events', { replace: true })
      } catch (error) {
        // Unauthorized: token expired
        logout()
      }
    }

    const rehydrate = async () => {
      if (!token) {
        dispatch({ type: 'set_rehydrating', rehydrating: false })
      } else {
        await fetchMe()
        dispatch({ type: 'set_rehydrating', rehydrating: false })
      }
    }

    rehydrate()
  }, [])

  const value = React.useMemo(
    () => ({ authState: state, login, logout, signTokenForSeller, setEvent, setEventStats, setSellers }),
    [state, login, logout, signTokenForSeller, setEvent, setEventStats, setSellers]
  )

  if (state.rehydrating) {
    return <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 text-center">Loading</div>
  }

  return <AuthContext.Provider value={value} {...props} />
}

const useAuth = (): Context => {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context as Context
}

export type { Category, Event, EventStats, Seller, Ticket }
export { AuthProvider, useAuth }